





























import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    AppFooter: () => import(/* webpackPrefetch: true */ "~/components/AppFooter.vue"),
    CartSidebar: () => import(/* webpackPrefetch: true */ "~/modules/checkout/components/CartSidebar.vue"),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ "~/modules/wishlist/components/WishlistSidebar.vue"),
    LoginModal: () => import(/* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"),
    Notification: () => import(/* webpackPrefetch: true */ "~/components/Notification.vue"),
    RegisterSuccess: () => import(/* webpackPrefetch: true */ "~/modules/customer/components/RegisterSuccess/RegisterSuccess.vue"),
},

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal, isForgotModalOpen , toggleForgotModal
    } = useUiState();
    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      toggleForgotModal,
      isForgotModalOpen
    };
  },
  head: {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/css/fonts.css' }],
  },
});
