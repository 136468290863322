import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62c8c2f3 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "" */))
const _37406a9f = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _5af6a57e = () => interopDefault(import('../pages/Catalog-Request-Form.vue' /* webpackChunkName: "" */))
const _9f22577c = () => interopDefault(import('../pages/Catalogs.vue' /* webpackChunkName: "" */))
const _41037c9b = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "" */))
const _52d5b462 = () => interopDefault(import('../pages/Distributor.vue' /* webpackChunkName: "" */))
const _e6e67920 = () => interopDefault(import('../pages/Error.vue' /* webpackChunkName: "" */))
const _d1629504 = () => interopDefault(import('../pages/FAQ.vue' /* webpackChunkName: "" */))
const _28c3517c = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _31b88362 = () => interopDefault(import('../pages/StoreFinder.vue' /* webpackChunkName: "" */))
const _335b6987 = () => interopDefault(import('../pages/TermsConditions.vue' /* webpackChunkName: "" */))
const _d2062376 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _1b306291 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _8c4096c8 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _ead3b3f0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7423f1af = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _ba0a1d10 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _45905e31 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _5beb26fc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _b007cb2c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _795e1aba = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _a96f9266 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyPaymentMethods.vue' /* webpackChunkName: "" */))
const _22ecef34 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _7a64e9ea = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _5d2567f4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _5d049aaa = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _7afaf6b6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _779b1325 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _172e1e38 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _3c846a09 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _65c8b6d6 = () => interopDefault(import('../pages/FindDistributor.vue' /* webpackChunkName: "pages/FindDistributor" */))
const _5351f9b2 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _268c18d7 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _3b2ae76e = () => interopDefault(import('../pages/SeoSitemapCatalog.vue' /* webpackChunkName: "pages/SeoSitemapCatalog" */))
const _74eac3c2 = () => interopDefault(import('../pages/SeoSitemapProduct.vue' /* webpackChunkName: "pages/SeoSitemapProduct" */))
const _208df8a8 = () => interopDefault(import('../modules/catalog/pages/categorySearch.vue' /* webpackChunkName: "" */))
const _185de220 = () => interopDefault(import('../modules/customer/components/LoginModal/forms/ForgotPasswordForm.vue' /* webpackChunkName: "" */))
const _502c7954 = () => interopDefault(import('../modules/checkout/pages/Checkout/Cancel.vue' /* webpackChunkName: "" */))
const _e6758ffa = () => interopDefault(import('../modules/checkout/components/VsfPaymentProvider.vue' /* webpackChunkName: "" */))
const _fc1faaba = () => interopDefault(import('../modules/checkout/components/Error.vue' /* webpackChunkName: "" */))
const _04250f01 = () => interopDefault(import('../modules/checkout/pages/Checkout/paypal/Success.vue' /* webpackChunkName: "" */))
const _33583a71 = () => interopDefault(import('../modules/customer/pages/MyAccount/Create.vue' /* webpackChunkName: "" */))
const _4efe9812 = () => interopDefault(import('../modules/customer/pages/Login.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us.html",
    component: _62c8c2f3,
    name: "about-us___"
  }, {
    path: "/cart",
    component: _37406a9f,
    name: "cart___absimporttools___"
  }, {
    path: "/Catalog-Request-Form.html",
    component: _5af6a57e,
    name: "Catalog-Request-Form____absimporttools___"
  }, {
    path: "/catalogs.html",
    component: _9f22577c,
    name: "catalog___absimporttools___"
  }, {
    path: "/contact-us.html",
    component: _41037c9b,
    name: "contact-us___absimporttools___"
  }, {
    path: "/distributors-2111.html",
    component: _52d5b462,
    name: "distributor___absimporttools___"
  }, {
    path: "/error.html",
    component: _e6e67920,
    name: "error_find____absimporttools___"
  }, {
    path: "/faq.html",
    component: _d1629504,
    name: "faq___"
  }, {
    path: "/privacy-policy.html",
    component: _28c3517c,
    name: "privacy-policy___absimporttools___"
  }, {
    path: "/storelocator.html",
    component: _31b88362,
    name: "storelocator___absimporttools___"
  }, {
    path: "/terms-conditions.html",
    component: _335b6987,
    name: "terms-conditions___absimporttools___"
  }, {
    path: "//AboutUs",
    component: _62c8c2f3,
    name: "AboutUs___"
  }, {
    path: "//cart",
    component: _37406a9f,
    name: "cart___"
  }, {
    path: "//Catalog-Request-Form",
    component: _5af6a57e,
    name: "Catalog-Request-Form___"
  }, {
    path: "//Catalogs",
    component: _9f22577c,
    name: "Catalogs___"
  }, {
    path: "//checkout",
    component: _d2062376,
    name: "checkout___",
    children: [{
      path: "billing",
      component: _1b306291,
      name: "billing___"
    }, {
      path: "payment",
      component: _8c4096c8,
      name: "payment___"
    }, {
      path: "shipping",
      component: _ead3b3f0,
      name: "shipping___"
    }, {
      path: "thank-you",
      component: _7423f1af,
      name: "thank-you___"
    }, {
      path: "user-account",
      component: _ba0a1d10,
      name: "user-account___"
    }]
  }, {
    path: "//Cms",
    component: _45905e31,
    name: "Cms___"
  }, {
    path: "//Contacts",
    component: _41037c9b,
    name: "Contacts___"
  }, {
    path: "//customer",
    component: _5beb26fc,
    meta: {"titleLabel":"My Account"},
    name: "customer___",
    children: [{
      path: "addresses-details",
      component: _b007cb2c,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___"
    }, {
      path: "my-newsletter",
      component: _795e1aba,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___"
    }, {
      path: "my-payment-methods",
      component: _a96f9266,
      meta: {"titleLabel":"My Payment Methods"},
      props: true,
      name: "my-payment-methods___"
    }, {
      path: "my-profile",
      component: _22ecef34,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___"
    }, {
      path: "my-reviews",
      component: _7a64e9ea,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___"
    }, {
      path: "my-wishlist",
      component: _5d2567f4,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___"
    }, {
      path: "order-history",
      component: _5d049aaa,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___"
    }, {
      path: "addresses-details/create",
      component: _7afaf6b6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___"
    }, {
      path: "//reset-password",
      component: _779b1325,
      alias: "/customer/account/createPassword",
      name: "reset-password___"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _172e1e38,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___"
    }, {
      path: "order-history/:orderId",
      component: _3c846a09,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___"
    }]
  }, {
    path: "//Distributor",
    component: _52d5b462,
    name: "Distributor___"
  }, {
    path: "//Error",
    component: _e6e67920,
    name: "Error___"
  }, {
    path: "//FAQ",
    component: _d1629504,
    name: "FAQ___"
  }, {
    path: "//FindDistributor",
    component: _65c8b6d6,
    name: "FindDistributor___"
  }, {
    path: "//Home",
    component: _5351f9b2,
    name: "Home___"
  }, {
    path: "//Page",
    component: _268c18d7,
    name: "Page___"
  }, {
    path: "//PrivacyPolicy",
    component: _28c3517c,
    name: "PrivacyPolicy___"
  }, {
    path: "//SeoSitemapCatalog",
    component: _3b2ae76e,
    name: "SeoSitemapCatalog___"
  }, {
    path: "//SeoSitemapProduct",
    component: _74eac3c2,
    name: "SeoSitemapProduct___"
  }, {
    path: "//StoreFinder",
    component: _31b88362,
    name: "StoreFinder___"
  }, {
    path: "//TermsConditions",
    component: _335b6987,
    name: "TermsConditions___"
  }, {
    path: "/catalogsearch/result",
    component: _208df8a8,
    name: "result search___absimporttools___"
  }, {
    path: "/customer/forgot_pass.html",
    component: _185de220,
    name: "fogot___absimporttools___"
  }, {
    path: "/seo-sitemap/catalog.html",
    component: _3b2ae76e,
    name: "seo_sitemap___absimporttools___"
  }, {
    path: "/seo-sitemap/product.html",
    component: _74eac3c2,
    name: "seo_sitemap_product___absimporttools___"
  }, {
    path: "/checkout/amazon/cancel",
    component: _502c7954,
    name: "amazon_cancel____absimporttools___"
  }, {
    path: "/checkout/amazon/success",
    component: _e6758ffa,
    name: "amazon_success___absimporttools___"
  }, {
    path: "/checkout/payment/error",
    component: _fc1faaba,
    name: "payment_error____absimporttools___"
  }, {
    path: "/checkout/paypal/cancel",
    component: _502c7954,
    name: "paypal_cancel____absimporttools___"
  }, {
    path: "/checkout/paypal/success",
    component: _04250f01,
    name: "paypal_success___absimporttools___"
  }, {
    path: "/checkout/stripe/error.html",
    component: _502c7954,
    name: "payment_error___absimporttools___"
  }, {
    path: "/customer/account/create",
    component: _33583a71,
    name: "create-account___absimporttools___"
  }, {
    path: "/customer/account/createPassword",
    component: _779b1325,
    name: "reset-password___"
  }, {
    path: "/customer/account/login.html",
    component: _4efe9812,
    name: "login___absimporttools___"
  }, {
    path: "/customer/addresses-details/create",
    component: _7afaf6b6,
    name: "addresses-details/create___"
  }, {
    path: "/checkout/thank-you/:slug+",
    component: _7423f1af,
    name: "checkout/thank-you___"
  }, {
    path: "/",
    component: _5351f9b2,
    name: "home___absimporttools___"
  }, {
    path: "/:slug+",
    component: _268c18d7,
    name: "page___absimporttools___"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
